
$table-th-font-weight: 1rem;

@import 'node_modules/bootstrap/scss/functions';
@import './_variables.scss';
@import './components.scss';

html,
body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  overflow-x: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

p {
  margin-bottom: 0;
}

// utilities
.pointer {
  cursor: pointer;
  box-shadow: none;
}

.btn:focus {
  outline: none;
}

.btn-primary:focus,
.btn-info:focus {
  box-shadow: none;
}

.hide {
  opacity: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.p-small {
  font-size: 0.7rem;
}

.item-shadow{
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, .11);
  background: white;

}

.alpha-80{
  opacity: .8;
}
